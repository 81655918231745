.project_section {
  position: relative;
  top: 0;
  /* width: 100vw; */

  background-color: var(--color-background);
}
.project_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  margin: auto;
}

.project_item_cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.project_item {
  margin-bottom: 40px;
  padding: 2em;
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  color: var(--color-white);
  text-align: center;
  border-radius: 15px;
}
.project_item .icon {
  text-align: center;
  font-size: 60px;
  display: block;
  margin-bottom: 10px;
  color: var(--color-yellow);
}

.project_item .desc h3 {
  line-height: 1.3;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
  position: relative;
  color: var(--color-white);
  margin-bottom: 1.5rem;
}
.project_item .desc h3:after {
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  content: "";
  width: 30px;
  height: 1px;
  background: var(--color-yellow);
  margin: 0 auto;
}

.project_item .desc span {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 10px;
  color: var(--color-yellow);
}

.project_item .desc p strong {
  color: var(--color-green);
}
.project_item:hover {
  background: var(--color-yellow);
  transition: ease 0.5s;
}
.project_item:hover .icon {
  color: var(--color-white);
}
.project_item:hover h3 {
  color: var(--color-navbar);
}
.project_item:hover h3:after {
  background: var(--color-navbar);
}
.project_item:hover .desc p {
  color: var(--color-navbar);
}
.project_item:hover .desc p strong {
  color: var(--color-navbar);
}

@media screen and (max-width: 1024px) {
  .project_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 2.5rem; */
    /* margin: auto; */

    /* position: relative; */

    /* width: 100vw; */
  }
  .detail {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .detail {
    display: none;
  }
}

.about_section {
  position: relative;
  top: 0;
  width: 100vw;
  /* height: 90vh; */
  background-color: var(--color-background);
}
.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 5%;
  justify-content: center;
}

.about__me {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  background: linear-gradient(
    45deg,
    tranparent,
    var(--color-primary),
    transparent
  );
  display: grid;
  place-items: center;
}
.about__me_image {
  position: relative;
  top: 0;
}
.about__me_image img {
  width: 100%;
  height: 100%;
  border-radius: 2rem;

  transform: rotate(0);
  transition: var(--transition);
}
/* 
.about__me_image:hover {
  transform: rotate(10deg);
} */

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about__card {
  text-align: center;
  /* border: 1px solid transparent; */
  padding: 1.5rem;
  border-radius: 5%;
  position: relative;
  width: auto;
  transition: var(--transition);
  color: var(--color-yellow);
  box-shadow: 10px 15px 24px 10px rgba(0, 0, 0, 0.3);
}

.about__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.about__icon {
  color: var(--color-yellow);
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  margin-top: 0.2rem;
}

.about__card h4 {
  font-size: 1.1rem;
  color: var(--color-green);
}

.about__card small {
  font-size: 0.8rem;
  color: var(--color-light);
}
.about__content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-white);
}

/* CTA */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: flex-start;
}

@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }
  .about__content p {
    margin: 1rem 0 1.5rem;
  }
  .about__cards {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 600px) {
  .about__me {
    width: 65%;
    margin: 0 auto 3rem;
  }
  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about__content {
    text-align: center;
  }
  .about__content p {
    margin: 1.5rem 0;
  }

  .cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
  }
}

.footer {
  background-color: var(--color-navbar);
  color: var(--color-white);
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;

  /* margin-top: 7rem; */
}
.footer a {
  color: var(--color-bg);
}

.footer a:hover {
  color: var(--color-yellow);
}
.footer__logo {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
  display: inline-block;
}

.permalinks {
  display: flex;
  flex-wrap: warp;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 1rem;
}

.footer__copyright {
  /* margin-bottom: 4rem; */
  color: var(--color-bg);
}

/* Socials */
.socials {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  /* position: absolute; */
  left: 0;
  bottom: 3rem;
}
.socials_link {
  margin: 0.5rem auto;
}

/* .socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
} */

@media screen and (max-width: 600px) {
  .permalinks {
    display: flex;
    align-items: center;
    text-align: center;
    padding: auto;
    gap: 15px;
    /* gap: 1.5rem; */
  }
}

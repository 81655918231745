.navbar {
  background-color: var(--color-navbar);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 20px;
  margin: 0 auto;
  transition: all 0.5s ease-in-out;
  z-index: 100;
}

.navbar ul {
  display: table;
  align-items: center;
}

.navbar ul li {
  /* display: inline-block; */
  height: 100%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;
}

.navbar ul li a {
  display: block;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: var(--color-font);
}

.navbar ul li a:hover {
  transition: ease 0.3s;
  color: var(--color-yellow);
}

.navbar > h1 > a {
  display: block;
  color: var(--color-yellow);
  font-size: 26px;
  margin-top: 0.5rem;
  z-index: 0;
  font-family: "Mustardo", sans-serif;
}

.navbar > h1 > a:hover {
  transition: ease 0.3s;
  color: var(--color-white);
}

@media screen and (max-width: 1024px) {
  header {
    height: 60vh;
  }
}

@media screen and (max-width: 600px) {
  .navbar {
    position: absolute;
    width: 100%;
    display: none;
  }
  .navbar > h1 {
    display: none;
  }
}

.skills_section {
  background-color: var(--color-background);
}
.skills__container {
  display: grid;
  grid-template-columns: 45% 45%;
  gap: 5%;
  justify-content: center;
}

.skills__container > div {
  background: var(--color-background-shadow);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
}

.skills__backend > h3,
.skills__frontend > h3 {
  margin-bottom: 1.5rem;
  color: var(--color-yellow);
}

.skills__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
}
.skills__details {
  display: flex;
  gap: 1rem;
}
.skills__details div {
  display: flex;
  justify-content: center;
  text-align: center;
}
.skills__details h4 {
  font-size: 0.95rem;
  color: var(--color-white);
  display: flex;
  align-items: center;
}
.skills__details img {
  width: 50px;
}

.skills__details_icons {
  margin-top: 6px;
  color: var(--color-primary);
}

@media screen and (max-width: 1024px) {
  .skills__container {
    grid-template-columns: 1fr;
  }
  .skills__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }
  .skills__content {
    padding: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .skills__container {
    gap: 1rem;
  }
  .skills__container > div {
    width: 100%;

    padding: 1.5rem 1rem;
  }
  .skills__details > div > small {
    display: none;
  }
  .skills__details > div {
    display: flex;
    align-items: center;
  }
}

.main {
  /* border: solid brown 5px; */
  position: relative;
  height: 100vh;
  width: 100vw;
  /* z-index: -1; */
}
.container {
  display: inline-block;
  /* position: absolute;

  bottom: 0;

  height: 90vh;
  width: 100vw;

  border: solid 2px blue; */
}
/* .home_text {
  text-align: center;
  position: absolute;
  top: 100%;
  left: 100%;
  width: 30vw;
  height: 20vh;
} */

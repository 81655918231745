@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("http://fonts.cdnfonts.com/css/mustardo");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-varient: rgba(77, 181, 255, 0.4);
  --color-white: #e5e5e5;
  --color-light: rgba(255, 255, 255, 0.6);
  --color-background-shadow: rgba(255, 255, 255, 0.1);
  --color-background: #242424;
  --color-navbar: #191919;
  --color-font: #e5e5e5;
  --color-yellow: #ffac00;
  --color-green: #6ec5b8;

  --transition: all 400 ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto", sans-serif;
  position: relative;
  width: 100vw;
  line-height: 1.7;
  background-color: var(--color-background);
}
section {
  position: relative;
  width: 100vw;
  /* height: 100vh; */
  padding: 10% 5% 5% 5%;
}

section > h1 {
  text-align: center;
  color: var(--color-white);

  margin-bottom: 4rem;
  font-size: 40px;
  font-weight: 700;
}
section > h2,
section > h5 {
  text-align: center;

  color: var(--color-yellow);
  margin-bottom: 3rem;
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-navbar);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  /* border: 1px solid var(--color-); */
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-yellow);
  color: var(--color-white);
}

.btn-primary {
  background: var(--color-yellow);
  color: var(--color-navbar);
  border: none;
}

/* Media  (Medium device*/
/* 
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  section {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
  section > h2 {
    margin-top: 2rem;
  }
} */

.experience_section {
  background-color: var(--color-background);
  position: relative;
}
.experience__container {
  display: grid;
  grid-template-columns: repeat(1fr, 1fr);
  gap: 1.5rem;
  margin-bottom: 3rem;
  color: white;
  /* justify-content: center; */
}
.container > p {
  font-size: 0.9rem;
}
.span_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.experience_card {
  display: flex;
  align-items: center; /* Vertically center the content */
}

.experience__container > div {
  background: var(--color-background-shadow);
  padding: 2.4rem 3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
}

.experience__backend > h3,
.experience__frontend > h3 {
  margin-bottom: 1.5rem;
  color: var(--color-yellow);
}

.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
}
.experience__details {
  display: flex;
  gap: 1rem;
}
.experience__details div {
  display: flex;
  justify-content: center;
  text-align: center;
}
.experience__details h4 {
  font-size: 0.95rem;
  color: var(--color-white);
  display: flex;
  align-items: center;
}
.experience__details img {
  width: 50px;
}

.experience__details_icons {
  margin-top: 6px;
  color: var(--color-primary);
}

.title {
  color: var(--color-yellow);
  margin-right: 10px; /* Add some space between h4 and p */
  font-size: 1.2rem; /* Adjust font size as needed */
}

.duration {
  font-size: 0.75rem;
  color: white;
}

@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }
  .experience__content {
    padding: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .experience__container {
    gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
  }
  .experience__container > div {
    width: 100%;
    padding: 1.5rem 1rem;
  }
  .experience__details > div > small {
    display: none;
  }
  .experience__details > div {
    display: flex;
    align-items: center;
  }
}

.contact_section {
  background-color: var(--color-background);
  padding-top: 10%;
}
.contact__container {
  display: grid;
  grid-template-columns: 20% 45%;
  gap: 5%;
  justify-content: center;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  /* border: solid 2px red; */
  /* position: relative; */
  /* width: 100%; */
}

.contact__option {
  text-align: center;
  /* border: 1px solid transparent; */
  border-radius: 5%;
  position: relative;
  width: auto;
  transition: var(--transition);
  color: var(--color-white);
  padding-bottom: 3px;
  box-shadow: 10px 14px 5px rgba(0, 0, 0, 0.1);
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.contact__option h5 {
  color: var(--color-yellow);
}

.contact__option_icon {
  /* width: 100px; */
  /* height: 100px; */
  /* background: #ffac00; */
  /* padding: 1rem 1rem 0.9rem 1rem; */
  /* margin: auto; */
  margin-bottom: 10px;

  font-size: 1.5rem;
  /*     
  color: black;
  background-color: var(--color-yellow); */
  border-radius: 50%;

  text-align: center;
  align-items: center;
}
.contact__option a {
  font-size: 0.8rem;
  margin-top: 0.7rem;
  display: inline-block;
  color: var(--color-primary);
}

.contact__option a:hover {
  color: var(--color-white);
}
.contact__option a {
  color: var(--color-green);
}
/* Form */

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
form input,
form textarea {
  font-size: 0.9rem;
}

::-webkit-input-placeholder {
  color: var(--color-white);
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-bg-variant);
  resize: none;
  color: var(--color-white);
  font: 800;
}

@media screen and (max-width: 1024px) {
  .contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* Socials */
.socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
  position: absolute;
  left: 1%;
  font-size: 1.8rem;
  bottom: 3rem;
  transition: ease 0.5s;
}

@media screen and (max-width: 600px) {
  .contact__container {
    width: var(--container-width-sm);
  }
  .contact__container {
    margin: auto;
    /* display: flex; */
  }
  .socials {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    right: 2px;
    bottom: 3rem;
  }
}
